import { Button, Form, Space, Image, Input, Toast, Radio, SafeArea, Picker, DatePicker, SpinLoading, Avatar, Checkbox, Popup, Result } from "antd-mobile";

import contractImg from "@assets/contract.svg";
import { useEffect, useState } from "react";
import { IFddAuth, IRegisterContract, getFddAuth, getRegisterContract, getUserInfo } from "../../apis/user";

export function SignPage() {
    useEffect(() => {
        loadFdd();
    }, []);

    const [isSigned, setIsSigned] = useState(false);

    const [fdd, setFdd] = useState<IFddAuth>();

    const [loading, setLoading] = useState(false);

    const loadFdd = async () => {
        setLoading(true);
        try {
            let res = await getFddAuth();
            setFdd(res.data);
            if (res.data.is_auth) {
                await loadUserInfo();
            }
            Toast.clear();
        } catch (e) {
            console.error(e);
        }
        setLoading(false);
    }

    const loadUserInfo = async () => {
        let userInfoRes = await getUserInfo();
        if (userInfoRes) {
            console.log(userInfoRes);

            let userInfo = userInfoRes.data;
            sessionStorage.setItem('user', JSON.stringify(userInfo));

            // 已签署
            if (userInfo.is_user_sign) {
                setIsSigned(true);
            } else {
                await loadContract();
            }
        }
    }

    const [errorMsg, setErrorMsg] = useState("");
    const [contract, setContract] = useState<IRegisterContract>();
    const loadContract = async () => {
        try {
            let res = await getRegisterContract();
            setContract(res.data);
        } catch (e: any) {
            console.error(e);
            if (e?.data?.msg) setErrorMsg(e.data.msg);
        }
    }

    return (
        <div>
            {
                !isSigned &&
                <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
                    <Image src={contractImg} width="90vw"></Image>
                    <h2>合同签约</h2>
                    {
                        loading || !fdd
                            ? <SpinLoading style={{ marginTop: 20 }} color='primary' />
                            : fdd.is_auth
                                ? contract
                                    ? <>
                                        <div style={{ fontSize: 16 }}>点击跳转电子签：<Button onClick={() => { window.open(contract.batch_sign_url, '_blank') }}>去签署合同</Button></div>
                                        <div style={{ marginTop: 20, textDecorationLine: 'underline', color: 'green' }} onClick={() => loadUserInfo()}>我已签署点击刷新页面状态</div>
                                    </>
                                    : <div style={{ fontSize: 16, color: 'red' }}>发生错误：{errorMsg}</div>
                                : <>
                                    <div style={{ fontSize: 16 }}>未注册法大大电子签：<Button onClick={() => { window.open(fdd.auth_url, '_blank') }}>点击注册</Button></div>
                                    <div style={{ marginTop: 20, textDecorationLine: 'underline', color: 'green' }} onClick={() => loadFdd()}>我已注册点击刷新页面状态</div>
                                </>
                    }
                </div>
            }

            {
                isSigned &&
                <Result
                    status='success'
                    title='认证成功'
                    description='您已完成认证，可关闭当前页面'
                />
            }
        </div>
    )

}