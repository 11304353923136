export function sleep(second: number) {
    return new Promise((resolve) => {
        setTimeout(() => {
            resolve(true);
        }, second * 1000);
    });
}

export function jsonSort(obj: Object) {
    return JSON.parse(JSON.stringify(obj, Object.keys(obj).sort()));
}

export function generateRandomString(length: number) {
    var result = '';
    var characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789';
    var charactersLength = characters.length;
    for (var i = 0; i < length; i++) {
        result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
}