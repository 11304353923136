import { AxiosProgressEvent } from "axios";
import { CommonResponse, axiosInstance } from ".";

/**
 * 字典
 */
export type IDictName = keyof IDictList

export interface IDictDataOption {
    name: string,
    value: number
}

export interface IDictData {
    type_name: string,
    options: IDictDataOption[]
}

export interface IDictList {
    global_sex?: IDictData, // 性别
    sys_nation?: IDictData, // 民族
    duty?: IDictData, // 职务
    scale?: IDictData, // 企业规模
    business?: IDictData, // 行业
    source_type?: IDictData, // 来源
    vip_question_type?: IDictData, // 案例分析分类
}

/**
 * @description: 获取服务器字典列表
 * @returns {Promise}
 */
export const getDictList = (types: IDictName[]): Promise<CommonResponse<IDictList>> => {
    return axiosInstance.post('/api/shares/dict', { types }).then(res => res.data);
};

// 多层级字典
export type IDictMultiName = keyof IDictMultiList

export interface IDictMultiDataOption {
    name: string,
    id: number,
    pid: number,
    is_hot?: boolean,
    initial?: string,
    children: IDictMultiDataOption[],
}

export interface IDictMultiData {
    type_name: string,
    options: IDictMultiDataOption[]
}

export interface IDictMultiList {
    task_category?: IDictMultiData, // 搜索-订单类型
}


export const getDictMultiList = (types: IDictMultiName[]): Promise<CommonResponse<IDictMultiList>> => {
    return axiosInstance.post('/api/shares/dict_multi', { types }).then(res => res.data);
};

export interface RegionListData {
    id: number,
    name: string,
    initial: string
}

interface RegionList {
    list: RegionListData[]
}

/**
 * @description: 获取省市区字典列表
 * @returns {Promise}
 */
export const getRegionByPid = (params: { pid?: number }): Promise<CommonResponse<RegionList>> => {
    return axiosInstance.get('/api/shares/region', { params }).then(res => res.data);
}


// 10 注册 20 登录 30 修改密码 40 绑定 50 转移管理员 60 更换手机
type codeTypeItems = 10 | 20 | 30 | 40 | 50 | 60
export const codeType: {
    [key: string]: codeTypeItems,
} = {
    register: 10, // 注册
    login: 20, // 登录
    password: 30, // 修改密码
    bind: 40, // 绑定
    transfer: 50, // 转移管理员
    changeMobile: 60 // 更换手机
};

export interface ISendVCodeForm {
    mobile: string,
    type: codeTypeItems
}

/**
 * 发送验证码
 * @param data 
 * @returns 
 */
export const sendVCode = (data: ISendVCodeForm): Promise<CommonResponse> => {
    return axiosInstance.post('/api/shares/sms_send', data).then(res => res.data);
};


/**
 * 上传文件类型
 */
export type uploadTypeValues =
    'default' | // 默认
    'blacklist' | // 黑名单
    'license' | // 营业执照
    'school' | // 学校
    'idcard' | // 身份证
    'staff_import' | // 导入员工
    'bank_card' | // 银行卡
    'work_order' | // 工作工单
    'work_plan' | // 工作计划
    'customer' | // 客户
    'work_record' | // 工作汇报
    'tracking' | // 轨迹追踪
    'company_logo' | // 企业logo
    'environment' | // 企业环境
    'salary' | // 薪资
    'salary_accounting_deduct' | // 薪资扣除
    'approval' | // 审批
    'tax_sheet_undeclare' | // 未申报薪资列表
    'staff_holiday' | // 年假
    'adviser_logo' |// 头像
    'order' | // 业务订单
    'pre_worksheet' | // 裁前导入
    'adviser_qualification' | // 资质
    'contract'; // 合同

export interface FileUpload {
    full_link: string,
    link: string,
    file_id: number,
    origin_name?: string,
    local_path?: string
}

/**
 * @description: 上传文件
 * @returns {Promise}
 */
export const uploadFile = ({ file, type, uploadProgressCallback }: { file: Blob, type: uploadTypeValues, uploadProgressCallback?: (progressEvent: AxiosProgressEvent) => void }): Promise<CommonResponse<FileUpload>> => {
    let form = new FormData();
    form.append('file', file);
    form.append('type', type);
    return axiosInstance.post('/api/shares/file_upload', form, { onUploadProgress: uploadProgressCallback }).then(res => res.data);
}

export interface IIdcardOcr {
    address: string
    authority: string
    birth: string
    id_num: string
    is_long_time: boolean
    name: string
    nation: string
    nation_code: number
    sex: string
    sex_code: number
    valid_date_end: string
    valid_date_start: string
}

/**
 * 身份证识别
 * @param params 
 * @returns 
 */
export const getIdcardOcrData = (data: { fid: number }): Promise<CommonResponse<IIdcardOcr>> => {
    return axiosInstance.post('/api/ocr/scan', { file_id: data.fid, type: 'idCard' }).then(res => res.data);
}