import { axiosInstance, CommonResponse } from ".";

export interface ILoginForm {
    code: string,
    mobile: string,
}

export interface ILoginData {
    token: string,
}

/**
 * @description: 用户登录
 */
export const login = (data: ILoginForm): Promise<CommonResponse<ILoginData>> => {
    return axiosInstance.post('/h5/link_user/login', data).then(res => res.data);
};

export interface IUserInfoRes {
    oauth_user: {
        id: number,
        openid: string,
        nickname: string,
        headimgurl: string,
        is_auth: boolean,
        auth_expired_at?: string
    },
    user_info: {
        id?: number,
        company_id?: number,
        sp_id?: number,
        real_name?: string,
        mobile?: string,
        idcard?: string,
        bank_no?: string,
        bank_name?: string,
        bank_deposit?: string,
        bank_place?: string
    },
    is_user_auth: boolean,
    is_user_sign: boolean
}

/**
 * @description: 获取用户信息
 * @returns {Promise}
 */
export const getUserInfo = (): Promise<CommonResponse<IUserInfoRes>> => {
    return axiosInstance.get('/h5/link_user/info').then(res => res.data);
};

export interface IRegisterForm {
    real_name: string,
    idcard: string,
    sex?: number,
    nation_id?: number,
    birthday?: string,
    address?: string,
    idcard_front_img: number,
    idcard_back_img: number,
    is_idcard_long_time: boolean,
    idcard_begin_date: string,
    idcard_expire_date: string,
    bank_no: string,
    bank_name: string,
    bank_deposit: string,
    bank_place: string
    company_id: number,
    sp_id: number
}

/**
 * 注册用户信息
 */
export const register = (data: IRegisterForm): Promise<CommonResponse> => {
    return axiosInstance.post('/h5/link_user/register', data).then(res => res.data);
};


export interface IFddAuth {
    is_auth: boolean,
    auth_url: string
}

/**
 * 获取法大大权限
 */
export const getFddAuth = (): Promise<CommonResponse<IFddAuth>> => {
    return axiosInstance.get('/h5/link_contract/person_auth_info').then(res => res.data);
};


export interface IRegisterContract {
    list: {
        id: number,
        contract_id: string,
        title: string,
        sign_type: number,
        created_at: string,
        initiator_openid: string,
        is_success: boolean,
        sign_type_text: string
    }[],
    batch_sign_url: string
}

/**
 * 获取注册时的签约合同
 */
export const getRegisterContract = (): Promise<CommonResponse<IRegisterContract>> => {
    return axiosInstance.post('/h5/link_contract/register_contract').then(res => res.data);
};